let connect_attempts = 0;
let default_constraints = {video: true};

let ws_server;
let ws_port;
let peer_connection;
let turnUsername;
let turnPassword;
let default_peer_id = Math.floor(Math.random() * 100);

function wait(ms){
   var start = new Date().getTime();
   var end = start;
   while(end < start + ms) {
     end = new Date().getTime();
  }
}

function resetState() {
    // This will call onServerClose()
    ws_conn.close();
}

function handleIncomingError(error) {
    console.log("ERROR: " + error);
    resetState();
}

function setError(text) {
    console.error("ERROR: " + text);
}

function onIncomingSDP(sdp) {
    peer_connection.setRemoteDescription(sdp).then(() => {
        // console.log("Remote SDP set");
        if (sdp.type != "offer")
            return;
        // console.log("Got SDP offer");
        peer_connection.createAnswer().then(onLocalDescription).catch(setError);

    }).catch(setError);
}

function onLocalDescription(desc) {
    // console.log("Got local description: " + JSON.stringify(desc));
    peer_connection.setLocalDescription(desc).then(function() {
        // console.log("Sending SDP " + desc.type);
        sdp = {'sdp': peer_connection.localDescription}
        ws_conn.send(JSON.stringify(sdp));
    });
}


function onIncomingICE(ice) {
    var candidate = new RTCIceCandidate(ice);
    peer_connection.addIceCandidate(candidate).catch(setError);
}
window.onConnectClicked = function(secret_code)  {
    // if (document.getElementById("peer-connect-button").value == "Disconnect") {
    //     resetState();
    //     return;
    // }
    var id = secret_code;
    if (id == "") {
        alert("Peer id must be filled out");
        return;
    }

    ws_conn.send("SESSION " + id);
    //setConnectButtonState("Disconnect");
}


function generateOffer() {
    peer_connection.createOffer().then(onLocalDescription).catch(setError);
}

function onServerMessage(event) {
    // console.log("Received " + event.data);
    switch (event.data) {
        case "HELLO":
            // console.log("Registered with server, waiting for call");
            return;
        case "SESSION_OK":
            // console.log("Starting negotiation");

            ws_conn.send("OFFER_REQUEST");
            // console.log("Sent OFFER_REQUEST, waiting for offer");
            return;

            if (!peer_connection)
                createCall(null)
            return;
        case "OFFER_REQUEST":
            // The peer wants us to set up and then send an offer
            if (!peer_connection)
                createCall(null)
            return;
        default:
            if (event.data.startsWith("ERROR")) {
                handleIncomingError(event.data);
                return;
            }
            // Handle incoming JSON SDP and ICE messages
            try {
                msg = JSON.parse(event.data);
            } catch (e) {
                if (e instanceof SyntaxError) {
                    handleIncomingError("Error parsing incoming JSON: " + event.data);
                } else {
                    handleIncomingError("Unknown error parsing response: " + event.data);
                }
                return;
            }

            // Incoming JSON signals the beginning of a call
            if (!peer_connection)
                createCall(msg);

            if (msg.sdp != null) {
                onIncomingSDP(msg.sdp);
            } else if (msg.ice != null) {
                onIncomingICE(msg.ice);
            } else {
                handleIncomingError("Unknown incoming JSON: " + msg);
            }
    }
}

const handleDataChannelOpen = (event) =>{
    // console.log("dataChannel.OnOpen", event);
};

const handleDataChannelError = (error) =>{
    // console.log("dataChannel.OnError:", error);
};

const handleDataChannelClose = (event) =>{
    // console.log("dataChannel.OnClose", event);
};

const handleDataChannelMessageReceived = (event) =>{
    //console.log("dataChannel.OnMessage:", event, event.data.type);
    //console.log("Received data channel message");

    if (typeof event.data === 'string' || event.data instanceof String) {
        // console.log('Incoming string message: ' + event.data);
        var event_json = JSON.parse(event.data);
        // console.log(event_json.msg_type)
        if(event_json.msg_type == "run_time_data"){
            document.getElementById("RTCar").innerText = event_json.car
            document.getElementById("RTBike").innerText = event_json.bike
            document.getElementById("RTBus").innerText = event_json.bus
            document.getElementById("RTVan").innerText = event_json.van
            document.getElementById("RTLorry").innerText = event_json.truck
            document.getElementById("RTTWheel").innerText = event_json.three
            document.getElementById("RTBicycle").innerText = event_json.cycle
            document.getElementById("RTHuman").innerText = event_json.human
            document.getElementById("RTSInfoDate").innerText = event_json.start
            document.getElementById("RTSInfoFPS").innerText = event_json.fps
        }
        // {"type":"run_time_data","Car":"94204739461650"}
        //textarea = document.getElementById("text")
        //textarea.value = textarea.value + '\n' + event.data
    } else {
        console.log('Incoming data message');
    }
    //send_channel.send("Hi! (from browser)");
};

function onDataChannel(event) {
    // console.log("Data channel created");
    let receiveChannel = event.channel;
    receiveChannel.onopen = handleDataChannelOpen;
    receiveChannel.onmessage = handleDataChannelMessageReceived;
    receiveChannel.onerror = handleDataChannelError;
    receiveChannel.onclose = handleDataChannelClose;
}

function getVideoElement() {
    return document.getElementById("stream");
}

function onRemoteTrack(event) {
    if (getVideoElement().srcObject !== event.streams[0]) {
        console.log('Incoming stream');
	//getVideoElement().autoplay;
        getVideoElement().srcObject = event.streams[0];
        getVideoElement().muted = true;
	getVideoElement().play();
    }
}

// let rtc_configuration = {
//     iceServers: [
//         {
//             urls: 'turn:172.104.167.254:3478?transport=tcp',
//             username: '1623697745@vc',
//             credential: '2r2UOfqZuImCgSrVPidjDxaM3rQ='
//         }
//     ]
// };

function createCall(msg) {
    // Reset connection attempts because we connected successfully
    connect_attempts = 0;
    let rtc_configuration = {
        iceServers: [
            {
                urls: 'turn:sig.aivision.asia:3478?transport=tcp',
                username: turnUsername,
                credential: turnPassword
            }
        ]
    };

    // console.log('Creating RTCPeerConnection');

    peer_connection = new RTCPeerConnection(rtc_configuration);
    //send_channel = peer_connection.createDataChannel('label', null);
    //send_channel.onopen = handleDataChannelOpen;
    //send_channel.onmessage = handleDataChannelMessageReceived;
    //send_channel.onerror = handleDataChannelError;
    //send_channel.onclose = handleDataChannelClose;
    peer_connection.ondatachannel = onDataChannel;
    peer_connection.ontrack = onRemoteTrack;
    //peer_connection.ontrack = onRemoteTrack;
    /* Send our video/audio to the other peer */
    //local_stream_promise = getLocalStream().then((stream) => {
    //    console.log('Adding local stream');
    //    peer_connection.addStream(stream);
    //    return stream;
    //}).catch(setError);

    if (msg != null && !msg.sdp) {
        // console.log("WARNING: First message wasn't an SDP message!?");
    }

    peer_connection.onicecandidate = (event) => {
        // We have a candidate, send it to the remote party with the
        // same uuid
        if (event.candidate == null) {
            // console.log("ICE Candidate was null, done");
            return;
        }
        ws_conn.send(JSON.stringify({'ice': event.candidate}));
    };

    if (msg != null)
        console.log("Created peer connection for call, waiting for SDP");

    //setConnectButtonState("Disconnect");
}

function onServerClose(event) {
    console.log('Disconnected from server');
    // resetVideo();

    if (peer_connection) {
        peer_connection.close();
        peer_connection = null;
    }

    // Reset after a second
    window.setTimeout(websocketServerConnect, 1000);
}

function onServerError(event) {
    // Retry after 3 seconds
    window.setTimeout(websocketServerConnect, 3000);
}

window.websocketServerConnect = function(turn_username,turn_password,stream_id)  {
    wait(2000);
    connect_attempts++;
    turnUsername = turn_username
    turnPassword = turn_password
    if (connect_attempts > 3) {
        console.log("Too many connection attempts, aborting. Refresh page to try again");
        return;
    }
    // Clear errors in the status span
    // Populate constraints
    //var textarea = document.getElementById('constraints');
    //if (textarea.value == '')
    //textarea.value = JSON.stringify(default_constraints);
    // Fetch the peer id to use
    peer_id = default_peer_id;
    ws_port = '8443';
    ws_server = "sig.aivision.asia";
    var ws_url = 'wss://' + ws_server + ':' + ws_port
    // console.log("Connecting to server " + ws_url);
    console.log(ws_url)

    ws_conn = new WebSocket(ws_url);
    /* When connected, immediately register with the server */
    ws_conn.addEventListener('open', (event) => {
        ws_conn.send('HELLO ' + peer_id);
        console.log("Registering with server");
        onConnectClicked(stream_id)
        // setConnectButtonState("Connect");
    });
    ws_conn.addEventListener('error', onServerError);
    ws_conn.addEventListener('message', onServerMessage);
    ws_conn.addEventListener('close', onServerClose);
}
